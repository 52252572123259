// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap';
import '../stylesheets/application';
import '@fortawesome/fontawesome-free/js/all';

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("trix")
require("@rails/actiontext")

document.addEventListener("turbolinks:load", function(){
  let globalAlert = document.querySelector(".global-alert");
  if (globalAlert) { // 表示位置を設定
    globalAlert.style.position = "fixed";
    globalAlert.style.left = '1rem';
    globalAlert.style.top = '1rem';
    globalAlert.hidden = false;
    globalAlert.classList.add("animate__animated", "animate__slideInDown", "animate__fast")
  }
})

// #submitの要素がクリックされたらdata-targetに設定している要素をクリックする
jQuery(document).on('turbolinks:load', function(){
  $("#submit")?.on("click", ()=>{
    $($("#submit").data("target"))?.trigger('click');
  })
});

const omit = text => len => ellipsis =>
  text.length >= len
    ? text.slice(0, len - ellipsis.length) + ellipsis
    : text
    

/* =================ハンバーガーメニュー============================ */
document.addEventListener("turbolinks:load", ()=>{
  let openBtn = document.querySelector(".openbtn");
  let closeBtn = document.querySelector(".closebtn");
  let globalNav = document.querySelector('#g-nav');
  let globalNavLink = document.querySelector('#g-nav a');
  
  if (!(openBtn && closeBtn && globalNav && globalNavLink)){return;}

  openBtn.addEventListener("click", ()=>{
    openBtn.classList.add('active');
    globalNav.classList.add('panelactive')
  });

  closeBtn.addEventListener("click", ()=>{
    openBtn.classList.remove('active');
    globalNav.classList.remove('panelactive')
  });

  globalNavLink.addEventListener("click", ()=>{
    openBtn.classList.remove('active');
    globalNav.classList.remove('panelactive')
  });
})

// フォームのアラート表示
document.addEventListener("turbolinks:load", ()=>{
  let errorContainer = document.querySelector("#error_explanation");
  if (errorContainer){
    errorContainer.classList.add("alert", "alert-danger");
  }
});
